<template>
  <div class="content">
    <!-- 头部部分 -->
    <Top @handleLogin="handleLogin" @clickCollect="handleCollect" @clickSearch="handleSearch"></Top>

    <!-- 导航栏部分 -->
    <div class="navbar">
      <!-- 导航标题 -->
      <div class="navbar-li font-color" :class="{ 'active': index == currentIndex }" v-for="(item, index) in navbarList"
        v-focusable :key="index" @onFocus="handleNavbarFocus(index)">
        <!-- @up="handleNavbarUp(i, $event)"
        @down="handleNavbarDown(i, $event)"
        @right="handleNavbarRight" -->
        {{ item.title }}
      </div>
    </div>


    <!-- main START -->
    <div class="main">
      <!-- 推荐 -->
      <div class="scroll">
        <div class="navbar-center" >
          <div class="navbar-center-li item5" v-focusable @on-focus="scrollfocus" v-for="(item, index) in content.recommend"
            :key="index" @click="handleDetail(item.id)">
            <div class="back-image">
              <img v-lazy="item.img" alt="">

              <div class="type">动画</div>

              <div class="desic">
                <div class="desic-center">
                  <div class="title">
                    {{ item.title }}
                  </div>
                  <div class="sub_title font-color">
                    {{ item.subtitle }}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      

      <!-- 导航分组 group / panel -->
      <div class="concentration" v-for="(group, i) in content.list" :key="i" @click="handleDetail(group.id)">
        <div class="title font-color-wirte">{{ group.group_title }}</div>
        <div class="row">
          <div class="row-item"
            :style="{ gridColumnStart: 'span ' + item.column, height: 1.26 + (item.column == 4 ? 3.89 : 1.2 * item.column) + 'rem' }"
            v-focusable v-for="(item, n) in group.list" :key="n">
            <img v-lazy="item.img" alt="图片">
            <div class="name font-color-wirte">{{ item.title }}</div>
            <div class="type font-color-wirte" v-if="item.label">{{ item.label }}</div>
          </div>
        </div>

        <!-- <div class="see-more font-color-wirte" @click="handleSelectLoding(group.group_title)">
          <div class="select"> 查看更多</div>
          <div class="iconfont icon-gengduo_"></div>
        </div> -->
      </div>

      <!-- main END -->
    </div>

    <div class="footer font-color-wirte">
      <div class="return-top column1">
        <div class="iconfont icon-shangla"></div>
        <div class="right">回到顶部</div>
      </div>
      <div class="return-top column2">
        <div class="iconfont icon-sousuo"></div>
        <div class="right">搜索</div>
      </div>
    </div>
  </div>
</template>


<script>
import Top from '../components/common/Top.vue'
import axios from 'axios'
import { focusable } from "vue-tv-focusable";

export default {
  components: {
    Top
  },
  data() {
    return {
      navbarList: [
        {
          id: 1,
          title: '精选',
          isActived: true,
        },
        {
          id: 2,
          title: '微课',
          isActived: false
        },
        {
          id: 3,
          title: '微课',
          isActived: false
        },
        {
          id: 4,
          title: '有声读物',
          isActived: false
        },
        {
          id: 5,
          title: '图片',
          isActived: false
        },
        {
          id: 6,
          title: '其他',
          isActived: false
        }

      ],

      // 当前导航下标
      currentIndex: 0,
      // 当前内容部分数据
      content: {},

      keycode: 0, // 记录键值，默认： 上键:38,下键:40，具体看配置的值
      inScroll: 0, //确保焦点进入scrollEl和移出的时候只运行一次focusable.scrollEl；


    }
  },

  mounted() {
    // 局部滚动配置
    focusable.scrollSpeed = 100; // 两次按键小于100毫秒的话则当作一次事件。
    focusable.offsetDistance = 150; // 边缘距离为150

    // 选择默认焦点元素
    this.$nextTick(() => {
      this.$tv.next(
        document.getElementsByClassName('navbar-li')[0]
      );
    });

  },
  destroyed() {
    // 为了不影响别的界面交互，销毁的时候，如果有全局的配置属性就重置成全局配置，没有全局配置的属性就重置成系统默认的配置
    this.$tv.reset();
  },
  methods: {
    // 处理导航被选择
    handleNavbarFocus(index) {
      this.currentIndex = index;
      // 拿当前导航的数据, 请求接口
      // this.content = jinxuan;
      this.$axios.request({
        url: '/api/home/index'
      }).then(resp => {
        this.content = resp.data.data
      })
    },
    handleNavbarUp(i, event) {
      // 在第一个menu按上，焦点不动
      // if (i == 1) {
      //   this.$tv.next(event.target);
      // }else{
      //   this.$tv.next("up")
      // }
    },
    handleNavbarRight(i, event) {
      // 在最下面一个menu按下，焦点不动，避免右边内容item自动聚焦
      // if (i == this.navbarList.length) {
      //   this.$tv.next(event.target);
      // }else{
      //   this.$tv.next("down")
      // }
    },
    handleNavbarDown() {
      //从menu到内容时固定到内容的第一个元素
      // this.$tv.next(
      //   this.$tv.getElementByPath("//div[@class='content']/div[1]"),
      //   false
      // );
    },

    // 局部滚动
    scrollfocus(e) {
       console.log("scrollfocus");
      this.inScroll = 1;
      // scroll获取焦点的时候将自身的focusable去掉，将内部class为item5的dom添加focusable.
      document.querySelector(".scroll").removeAttribute(focusable.itemAttrname);
      document.querySelectorAll(".item5").forEach((e) => {
        e.setAttribute(focusable.itemAttrname, "");
      });
      // 将.scroll设置为滚动区域。
      focusable.scrollEl = document.querySelector(".scroll");
      // 按上下左右键分别定位到scroll里面的不同的el，具体跳转逻辑需要根据实际情况来
      if (this.keycode === 38) {
        const $item5list = document.querySelectorAll(".item5");
        focusable.next($item5list[$item5list.length - 10], false);
      } else if (this.keycode === 40 || this.keycode === 39) {
        focusable.next(document.querySelector(".item5"), false);
      }else if (this.keycode === 37) {
        const $item5list = document.querySelectorAll(".item5");
        focusable.next($item5list[9], false);
      }
      // 将.scroll距离边界100处的地方，防止和聚焦过程中滚动条滚动冲突，可以延时调用scrollTo,
      setTimeout(() => {
        focusable.scrollTo({
          targetEl: document.querySelector(".scroll"),
          offset: 100, // 距离边界100
        });
      }, 300);
    },

    // 点击登录跳转至登录页面
    handleLogin() {
      this.$router.push({ path: 'login' })
    },

    // 点击历史跳转至历史页面
    handleCollect() {
      this.$router.push({ path: 'collect' })
    },

    // 点击搜索跳转至该页面
    handleSearch() {
      this.$router.push({
        path: 'search'
      })
    },

    // 点击视频封面列表跳转详情
    handleDetail(id) {
      console.log(id, '-------');
      this.$router.push({
        name: 'detail',
        params: {
          id: id
        }
      })
    },

    // 点击查看更多跳转至对应页面
    handleSelectLoding(item) {
      this.$router.push({
        name: 'seemore',
        params: {
          title: item
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
@import './css/index/index.less';

.focus {
  // transform: scale(1.1) !important;
  box-shadow: 0 0 0.1rem #9aa7b1;
  // box-shadow: 0 0 0.6rem red;

  border-radius: 0.08rem;
}
</style>