<template>
    <div>
        <div class="header">
            <div class="header-left">
                <div class="row" v-focusable @click="tapSearch">
                    <div class="iconfont icon-sousuo font-color"></div>
                    <div class="name font-color">搜索</div>
                </div>

                <div class="row" v-focusable @click="tapLogin">
                    <div class="iconfont icon-denglu font-color"></div>
                    <div class="name font-color">登录</div>
                </div>

                <div class="row" v-focusable @click="tapCollect">
                    <div class="iconfont icon-lishi font-color"></div>
                    <div class="name font-color">历史</div>
                </div>
            </div>
            <div class="header-right">
                <div class="time font-color ">
                    09:40PM
                </div>
                <div class="logo">
                    <img src="../../assets/image/logo.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },

    methods: {
        tapSearch() {
            this.$emit('clickSearch')
        },
        tapLogin() {
            this.$emit('handleLogin')
        },

        tapCollect() {
            this.$emit('clickCollect')
        },
    }
}
</script>


<style lang="less" scoped>
.focus {
  transform: scale(1.1);
}
.header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 0 0.9rem;

    .header-left {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        // .row + .row {
        //     margin-left: 0.2rem;
        // }

        .row {
            margin-right: 0.2rem;
            width: 1.38rem;
            height: 0.52rem;
            line-height: 0.52rem;
            display: flex;
            justify-content: center;
            background-color: #2C3941;
            border-radius: 0.5rem;

            .icon-sousuo,
            .icon-denglu,
            .icon-lishi {
                font-size: 0.28rem;
            }

            .name {
                font-size: 0.28rem;
                padding-left: 0.1467rem;
            }
        }

    }

    .header-right {
        display: grid;
        grid-template-columns: repeat(4, auto);
        grid-gap: 0.2rem;
        align-items: center;

        .time {
            font-size: 0.32rem;
            letter-spacing: 0.01rem;
        }

        .logo {
            width: 1.42rem;
            height: 0.58rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}</style>